




































































































































































.main-content {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
}

.tab {
    text-align: right;
}

.content {
    flex: 1;
    height: 1%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.goods-img {
    max-width: 200px;
    height: 100px;
}

::v-deep .custom-dialog {
    min-width: 500px;
}
